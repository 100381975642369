import { Spinner } from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";

const StyledFlexSpinner = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface FlexSpinnerProps {
  "data-testid"?: string;
  padding?: React.CSSProperties["padding"];
  spinnerSize?: number;
}

const FlexSpinner = ({
  "data-testid": dataTestId,
  padding,
  spinnerSize = 24,
}: FlexSpinnerProps) => {
  return (
    <StyledFlexSpinner data-testid={dataTestId} style={{ padding }}>
      <Spinner
        data-testid={dataTestId && `${dataTestId}-spinner`}
        spinnerSize={spinnerSize}
      />
    </StyledFlexSpinner>
  );
};

export default FlexSpinner;
