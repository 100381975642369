import "core-js/features/set/is-disjoint-from";

/**
 * Returns whether any elements are shared between `a` and `b`
 * @param a
 * @param b
 */
export default function intersects<T>(a: Iterable<T>, b: Iterable<T>): boolean {
  const isDisjoint = new Set(a).isDisjointFrom(new Set(b));
  return !isDisjoint;
}
