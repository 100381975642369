import { AppOktaRole } from "../.rest-hooks/types";
import Redirect from "../components/ui/Redirect/Redirect";
import type { JwtClaims, NextPageWithLayout } from "./_app";
import "core-js/features/set/is-disjoint-from";
import intersects from "../utils/intersects/intersects";
import useAuthAccessToken from "../hooks/useAuthAccessToken";
import { jwtDecode } from "jwt-decode";

const BFRMP_DEFAULT_ROLES = [
  AppOktaRole.RMP_Project_Admin,
  AppOktaRole.RMP_NPWS,
  AppOktaRole.RMP_BFCC,
  AppOktaRole.RMP_BFMC,
  AppOktaRole.RMP_BFRP_Users,
] as const; // fill according to requirements

/**
 * Returns whether a user should be redirected to BFRMP projects by default.
 * This will be true if the user belongs to any one of the roles in `BFRMP_DEFAULT_ROLES`.
 */
const isBfrmpProjectsDefault = (roles: readonly string[]): boolean => {
  return intersects(roles, BFRMP_DEFAULT_ROLES);
};

const ProjectsPage: NextPageWithLayout = () => {
  const accessToken = useAuthAccessToken();
  const { groups } = jwtDecode<JwtClaims>(accessToken);

  if (isBfrmpProjectsDefault(groups)) {
    return <Redirect to="/bfrmp" />;
  }

  return <Redirect to="/local" />;
};

export default ProjectsPage;
