import type { Url } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { useEffect } from "react";
import FlexSpinner from "../FlexSpinner/FlexSpinner";

interface RedirectProps {
  to: Url;
  as?: Url;
  replace?: boolean;
}

const Redirect = ({ to, as, replace }: RedirectProps) => {
  const router = useRouter();
  const navigate = replace ? router.replace : router.push;

  useEffect(() => {
    void navigate(to, as);
  }, [navigate, to, as]);

  return <FlexSpinner />;
};

export default Redirect;
